body {
  margin: 0;
  font-family: proxima-nova, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:hover {
  color: white;
  text-decoration: underline;
}

.bg-cvc {
  background-color: #A5312A;
  color: white;
}

.table td, .table th {
  vertical-align: middle;
}

.table th {
  font-size: 16px;
}

.table td {
  font-size: 16px;
}

.table img {
  width: 64px;
}

.table a {
  color: #A5312A;
  text-decoration-line: underline;
  font-weight: bold;
}