.container {
    padding: 0;
}

.extra-padding {
    padding: 18px 0;
}

.sectionLink {
    color: black;
    border-bottom: 1px solid #DDD;
}

.sectionLink:hover {
    cursor: pointer;
    color: #A5312A;
    text-decoration: none;
}

.article {
    margin-top: 36px;
    margin-bottom: 36px;
    width: 90%;
    background-color: white;
    border-radius: 12px;
}

.article-title {
    padding: 8px 24px;
    font-size: 32px;
    color: #A5312A;
    font-weight: bold;
}

.header-title {
    padding: 8px 24px;
    font-size: 40px;
    color: #333;
    font-weight: bold;
}

.article-img {
    width: 100%;
    object-fit: cover;
    max-height: 400px;
}

.article-content {
    padding: 24px;
}

.titles {
    font-weight: bold;
    font-size: 32px;
}

.headline { 
    font-weight: bold;
    font-size: 20px;
}

.preview {
    font-size: 16px;
    line-height: 24px;
}

.borderRight {
    border-right: 1px solid #DDD;
}

.mid-title {
    font-weight: bold;
    font-size: 20px;
}

.startup {
    margin-top: 12px;
}

.startup a {
    color: #A5312A;
    text-decoration-line: underline;
    font-weight: bold;
    font-size: 24px;
}

.startup p {
    font-size: 20px;
    line-height: 32px;
}

@media (max-width: 990px) {
    .borderRight {
        border-right: none;
    }
}